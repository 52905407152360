import { FormProvider, getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node'
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import { login, requireAnonymous } from '#app/utils/auth.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { useIsPending } from '#app/utils/misc.js'
import { PasswordSchema, UsernameSchema } from '#app/utils/user-validation.ts'
import { handleNewSession } from './login.server.ts'

const LoginFormSchema = z.object({
  username: UsernameSchema,
  password: PasswordSchema,
  redirectTo: z.string().optional(),
  remember: z.boolean().optional(),
})

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request)
  return json({})
}

export async function action({ request }: ActionFunctionArgs) {
  await requireAnonymous(request)
  const formData = await request.formData()
  checkHoneypot(formData)
  const submission = await parseWithZod(formData, {
    schema: intent =>
      LoginFormSchema.transform(async (data, ctx) => {
        if (intent !== null) return { ...data, session: null }

        const session = await login(data)
        if (!session) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid username or password',
          })
          return z.NEVER
        }

        return { ...data, session }
      }),
    async: true,
  })

  if (submission.status !== 'success' || !submission.value.session) {
    return json(
      { result: submission.reply({ hideFields: ['password'] }) },
      { status: submission.status === 'error' ? 400 : 200 },
    )
  }

  const { session, remember, redirectTo } = submission.value

  return handleNewSession({
    request,
    session,
    remember: remember ?? false,
    redirectTo,
  })
}


/*function Test({ test = "" }: { test?: any }) {
  console.log("render Test", test)
  return (
    <span>{test}</span>
  )
}*/




export default function LoginPage() {
  const actionData = useActionData<typeof action>()
  const isPending = useIsPending()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')

  const [form, fields] = useForm({
    id: 'login-form',
    constraint: getZodConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: LoginFormSchema })
    },
    shouldRevalidate: 'onInput',
  })

  console.log("render Main")

  return (
    <div className="flex min-h-full flex-col justify-center  pt-20">
      <div className="mx-auto w-full max-w-md">
        <div className="flex flex-col gap-3 text-center">
          <h1 className="text-h1">Welcome back!</h1>
          <img

            className="object-cover w-32 mx-auto"
            alt={'logo'}
            src={'/img/tobili-logo.png'}
          />
          <p className="text-body-md text-muted-foreground">
            Login
            {/* <Test test={fields.username.value} />*/}
          </p>
        </div>


        <div className="mx-auto w-full max-w-md px-8">
          <FormProvider context={form.context}>
            <Form method="POST" {...getFormProps(form)}>
              <HoneypotInputs />
              <Field
                labelProps={{ children: 'Username' }}
                inputProps={{
                  ...getInputProps(fields.username, { type: 'text' }),
                  autoFocus: true,
                  className: 'lowercase',
                  autoComplete: 'username',

                }}
                errors={fields.username.errors}
              />

              <Field
                labelProps={{ children: 'Password' }}
                inputProps={{
                  ...getInputProps(fields.password, {
                    type: 'password',
                  }),
                  autoComplete: 'current-password',
                }}
                errors={fields.password.errors}
              />

              <div className="flex justify-between">
                <CheckboxField
                  labelProps={{
                    htmlFor: fields.remember.id,
                    children: 'Remember me',
                  }}
                  buttonProps={getInputProps(fields.remember, {
                    type: 'checkbox',
                  })}
                  errors={fields.remember.errors}
                />
                <div>
                  <Link
                    to="/forgot-password"
                    className="text-body-xs font-semibold"
                  >
                    Password dimenticata?
                  </Link>
                </div>
              </div>

              <input
                {...getInputProps(fields.redirectTo, { type: 'hidden' })}
              />
              <ErrorList errors={form.errors} id={form.errorId} />

              <div className="flex items-center justify-between gap-6 pt-3">
                <StatusButton
                  className="w-full"
                  status={isPending ? 'pending' : form.status ?? 'idle'}
                  type="submit"
                  disabled={isPending || !form.valid}
                >
                  Log in
                </StatusButton>
              </div>
            </Form>
          </FormProvider>

        </div>

      </div>
    </div>
  )
}

export const meta: MetaFunction = () => {
  return [{ title: 'Login to Epic Notes' }]
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />
}
